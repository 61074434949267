<template>
  <div class="search">
    <div class="search_top">
      <el-input
        placeholder="请输入内容"
        v-model="input2"
        class="login-form-input"
        @keyup.enter.native="Search"
      >
      </el-input>
      <el-button
        type="primary"
        @click="Search"
        style="margin-left:-3px;font-size:12px;margin-top:-3px"
        >搜索文档</el-button
      >
    </div>
    <div class="search_content">
      <div class="content_nav">
        <span class="left">搜索网页></span>
        <div class="right">
          找到相关文章<span class="blue">{{ this.blue }}</span
          >篇
        </div>
      </div>
      <ul class="xl">
        <li
          class="plcont"
          v-for="(item, index) in Collectionlist"
          :key="index"
          @click="change1(item)"
        >
          <div class="olof">
            <el-image :src="item.img"></el-image>
            <span>{{ item.name }}</span>
          </div>
          <div class="bq">
            <span v-for="inx in item.keyword" :key="inx">{{ inx }}&emsp;</span>
          </div>
          <div class="cont">{{ item.time }}</div>
          <div class="cg">
            <span>{{ item.Time }}上传</span>|<span>{{ item.pageall }}页</span
            >|<span>{{ item.download }}下载</span>|<span
              >{{ item.Price }}知识币</span
            >|<span>作者:{{ item.UserauthorName }}</span>
          </div>
          <el-button type="primary" size="small" @click.stop="change(item)"
            >下载</el-button
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE } from "@/services/dao.js";
import { getTimeFormat } from "@/js/common";
export default {
  name: "search",
  props: {
    msg: String,
  },
  data() {
    return {
      input2: "",
      info: true,
      blue: "",

      Collectionlist: [
        {
          img: require("@/assets/img/WORD.png"),
          name: "利用python进行数据分析",
          score: "4.5",
          read: "3w",
          download: 590,
          Time: "2020-6-3",
          page: "14",
          size: "168kb",
          url:
            "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2594104736,4220653550&fm=26&gp=0.jpg",
          Price: "30",
          content:
            "这种用整数表示的方法称为分类或字典编码表示法。不同值得数组称为分类、字典或数据级。本书中，我们使用分类的说法。表示分类的整数值称为分类编码或简单地称为编码。分类表示可以在进行分析时大大的提高性能。你也可以在保持编码不变的情况下，对分类进行转换。一些相对简单的转变例子包括重命名分类。加入一个新的分类，不改变已经存在的分类的顺序或位置。pandas的分类类型pandas有一个特殊的分类类型，用于保存使用整数分类表示法的数据。看一个之前的Series例子：",
          list: ["数据分析", "实用科学", "计算机", "编译器"],
          Collection: "2",
          comment: "3",
          circleurl:
            "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
          sizeList: ["large", "medium", "small"],
          user: "aaaaaaaa",
        },
      ],
    };
  },
  created() {
    this.input2 = this.$route.params.input;
    this.Search();
  },
  methods: {
    async Search(limit, offset) {
      if (this.input2 == undefined || this.input2 == null) {
        this.input2 = "";
      }
      let info = await QUERY(
        "post",
        "",
        'ZYDocument( where: {name: {_like: "%' +
          this.input2 +
          '%"}},limit: ' +
          10 +
          ", offset: " +
          0 +
          ") {id img  name  format   keyword Time pageall download Price user UserauthorName }"
      );
      for (let i = 0; i < info.data.ZYDocument.length; i++) {
        info.data.ZYDocument[i].keyword = eval(info.data.ZYDocument[i].keyword);
        info.data.ZYDocument[i].Time = getTimeFormat(
          Date.parse(info.data.ZYDocument[i].Time)
        );
        switch (info.data.ZYDocument[i].format) {
          case "docx":
            info.data.ZYDocument[i].img = require("@/assets/icon/WORD@2x.png");
            break;
          case "txt":
            info.data.ZYDocument[
              i
            ].img = require("@/assets/icon/txt(1)@2x.png");
            break;
          case "pdf":
            info.data.ZYDocument[i].img = require("@/assets/icon/PDF48@2x.png");
            break;
          case "excel":
            info.data.ZYDocument[
              i
            ].img = require("@/assets/icon/excel(2) 拷贝@2x.png");
            break;
          case "ppt":
            info.data.ZYDocument[i].img = require("@/assets/icon/ppt@2x.png");
            break;
          default:
            info.data.ZYDocument[i].img = require("@/assets/icon/？@2x.png");
        }
      }
      this.Collectionlist = info.data.ZYDocument;
      this.blue = info.data.ZYDocument.length;
      // this.list.splice(0, this.list.length);
      // info.data.WdQuestion.forEach((element) => {
      //   this.list.push({
      //     id: element.id,
      //     questionTitle: element.questionTitle,
      //     questionContent: element.questionContent,
      //     questionAnswer:
      //       "首先了解一下自己是怎样问老师的？ 氧化铁正确的名称是:Fe2O3； 而铁锈的主要成分是Fe3O4； 氧化铁与盐酸反应的化学方程式应该是： Fe2O3+6HCl=2FeCl3+3H2O 而铁锈与盐酸反应： Fe3O4+8HCl=FeCl2+2FeCl3+4H2O (这是复分解氧化铁正确的主要化学方程式应该是...",
      //   });
      // });
      // this.totlePage = info.data.WdQuestion_aggregate.aggregate.count;
    },
    Release() {
      this.$router.push({ name: "Release", params: { userId: 123 } });
    },
    change(value) {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000",
        });
      } else {
        this.$router.push({
          path: "/Resource_download",
          query: {
            id: value.id,
          },
        });
      }
    },
    change1(value) {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000",
        });
      } else {
        this.$router.push({
          path: "/Resource_details",
          query: {
            id: value.id,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.search {
  margin: 10px 344px 10px 344px;
}
.plcont {
  overflow: hidden;
  padding-bottom: 30px;
  border-bottom: 1px dashed #f7f7f7;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 80%;
}
.search_top {
  padding: 0 30px 0 30px;
}
.content_nav {
  overflow: hidden;
  margin-top: 30px;
}
.content_nav .left {
  float: left;
}
.content_nav .right {
  float: right;
}
.xl {
  background-color: #ffffff;
  padding: 20px;
}
.bq {
  margin-top: 20px;
}
.bq span {
  border-radius: 30px;
  border: 0.007353rem solid #bfbfbf;
  /* padding: 0.036765rem; */

  background-color: #eeeeee;
  padding: 2px 10px 2px 10px;
}
.cont {
  margin-top: 10px;
}
.cg {
  margin-top: 10px;
  float: left;
}
.cg span {
  margin-right: 10px;
  margin-left: 10px;
}
.olof {
  cursor: pointer;
  line-height: 32px;
}
.olof .el-image {
  width: 24px;
  height: 30px;
  float: left;
  margin-right: 10px;
}
li button {
  right: 0;
  float: right;
  width: 70px;
}
.blue {
  color: #0084ff;
}
</style>
